import $ from "jquery";
import Swal from "sweetalert2";
import urls from "../urls";

export default function () {
    $(".js-login-form").on("submit", function (e) {
        e.preventDefault();

        if (!$(this).valid()) return;

        var formData = $(this).serialize();
        window.waitMe($(this).closest(".js-login-form"));
        $('.js-submit-login-form').prop('disabled', true);
        if (formData) {
            $.ajax({
                url: window.GLOBALS.baseLink + urls.login,
                type: "POST",
                data: formData,
                dataType: "json",
                success: function (response) {
                    if (response) {
                        if (response.IsSuccess) {
                            window.toastMessage(
                                response.Title,
                                response.Message,
                                response.Class
                            );
                            if (response.Redirect) {
                                setTimeout(function () {
                                    window.location.href = response.Redirect;
                                    window.closeWaitMe(
                                        $(document).find(".js-login-form")
                                    );
                                    $('.js-submit-login-form').prop('disabled', false);
                                }, 1000);
                            }
                        } else {
                            window.toastMessage(
                                response.Title,
                                response.Message,
                                response.Class
                            );
                            window.closeWaitMe(
                                $(document).find(".js-login-form")
                            );
                            setTimeout(() => {
                                $('.js-submit-login-form').prop('disabled', false);
                            }, 750);
                        }
                    }
                },
            });
        }
    });
}
