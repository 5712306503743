export default {
    register: "post-register",
    login: "post-login",
    recoverPassword: "./forgot-password",
    savePassword: "./save-password",
    sendTicketMessage: "post-ticket-send-message",
    markAsSolvedTicket: "post-solved-ticket",
    insertTicket: "post-insert-ticket",
    profileUpdate: "post-profile-update",
    profileSavePasword: "post-password-update",
    profileSaveAddress: "post-save-address",
    fetchAddress: "fetch-address",
    updateAddress: "post-update-address",
    setPrimaryAddress: "post-primary-address",
    deleteAddress: "post-delete-address",
    checkDomain: "post-check-domain",
    calculatePrice: "post-calculate-price",
    fillContracts: "post-contracts",
    saveOrder: "post-save-order",
    prepare3dPayment: "post-prepare-3d-payment",
    cancelRequest: "post-cancel-request",
};
