import $ from "jquery";
import Swal from "sweetalert2";
import urls from "../urls";

export default function () {
    updateProfile();
    updatePassword();
    handleAddressType();
    saveCustomerAddress();
    fetchAddressData();
    updateCustomerAddress();
    setPrimaryAddress();
    deleteAddress();
}

function updateProfile() {
    $(".js-save-profile").on("submit", function (e) {
        e.preventDefault();

        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this), $(this).data("loading-text"));

        $.ajax({
            url: window.GLOBALS.baseLink + urls.profileUpdate,
            type: "POST",
            data: formData,
            dataType: "json",
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000);
                        }
                        window.closeWaitMe($that);
                    } else {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        window.closeWaitMe($that);
                    }
                } else {
                    window.closeWaitMe($that);
                }
            },
        });
    });
}

function updatePassword() {
    $(".js-update-password").on("submit", function (e) {
        e.preventDefault();

        $(".js-update-password").validate({
            rules: {
                NewPassword: "required",
                NewPasswordAgain: {
                    equalTo: "#NewPassword",
                },
            },
        });
        const that = $(this);

        if (!$(this).valid()) {
            return;
        }

        var formData = new FormData($(this)[0]);
        window.waitMe($(this), $(this).data("loading-text"));

        $.ajax({
            url: window.GLOBALS.baseLink + urls.profileSavePasword,
            type: "POST",
            data: formData,
            dataType: "json",
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000);
                        }
                        window.closeWaitMe(that);
                    } else {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        window.closeWaitMe(that);
                    }
                } else {
                    window.closeWaitMe(that);
                }
            },
        });
    });
}

function handleAddressType() {
    $(document).on("change", "[name=AddressType]", function (e) {
        if ($(this).val() == "2") {
            $(".js-show-company-input").removeClass("d-none");
            $(".js-hide-company-input").addClass("d-none");
        } else {
            $(".js-show-company-input").addClass("d-none");
            $(".js-hide-company-input").removeClass("d-none");
        }
    });
}

function saveCustomerAddress() {
    $(document).on("submit", ".js-customer-address-form", function (e) {
        e.preventDefault();

        if (!$(this).valid()) return;

        $("[name=Phone]").unmask();
        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this), $(this).data("loading-text"));

        $.ajax({
            url: window.GLOBALS.baseLink + urls.profileSaveAddress,
            type: "POST",
            data: formData,
            dataType: "json",
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000);
                        }
                        window.closeWaitMe($that);
                    } else {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        window.closeWaitMe($that);
                    }
                }
            },
        });
    });
}

function fetchAddressData() {
    $(".js-fetch-address").on("click", function (e) {
        e.preventDefault();

        const addressId = $(this).data("address-id");
        const customerId = $(this).data("customer-id");
        const token = $(this).data("token");
        window.waitMe(
            $("#EditAddressModal .modal-dialog"),
            $(this).data("loading-text")
        );

        if (addressId) {
            $.ajax({
                url: window.GLOBALS.baseLink + urls.fetchAddress,
                type: "GET",
                data: {
                    AddressID: addressId,
                    CustomerID: customerId,
                    Token: token,
                },
                dataType: "json",
                success: function (response) {
                    if (response) {
                        if (response.IsSuccess) {
                            if (response.Address) {
                                let addresType = 0;
                                let cityID = 0;
                                setTimeout(() => {
                                    $.each(response.Address, function (i, e) {
                                        if (i == "AddressType") {
                                            addresType = e;
                                            $(`.js-customer-edit-address-form`)
                                                .find(
                                                    `[name=AddressType][value=${e}]`
                                                )
                                                .prop("checked", true)
                                                .trigger("change");
                                        }

                                        if (i == "CityID") {
                                            cityID = e;
                                        }

                                        if (
                                            i != "AddressType" ||
                                            i != "CityID"
                                        ) {
                                            $(`.js-customer-edit-address-form`)
                                                .find(`[name=${i}]`)
                                                .not("[name=AddressType]")
                                                .val(e);
                                        }
                                    });
                                }, 500);

                                setTimeout(() => {
                                    $(
                                        ".js-customer-edit-address-form .js-select-country"
                                    ).trigger("change");
                                    handleAddressType();
                                }, 750);

                                setTimeout(() => {
                                    if ($(".js-select-country option").length) {
                                        $(
                                            ".js-customer-edit-address-form .js-city-wrapper"
                                        )
                                            .val(cityID)
                                            .prop("selected", true)
                                            .trigger("change");
                                    }
                                    window.closeWaitMe(
                                        $("#EditAddressModal .modal-dialog")
                                    );
                                }, 1000);
                            }
                        } else {
                            window.toastMessage(
                                response.Title,
                                response.Message,
                                response.Class
                            );
                            window.closeWaitMe(
                                $("#EditAddressModal .modal-dialog")
                            );
                        }
                    }
                },
            });
        }
    });
}

function updateCustomerAddress() {
    $(document).on("submit", ".js-customer-edit-address-form", function (e) {
        e.preventDefault();

        if (!$(this).valid()) return;

        $("[name=Phone]").unmask();
        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this), $(this).data("loading-text"));

        $.ajax({
            url: window.GLOBALS.baseLink + urls.updateAddress,
            type: "POST",
            data: formData,
            dataType: "json",
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000);
                        }
                        window.closeWaitMe($that);
                    } else {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        window.closeWaitMe($that);
                    }
                }
            },
        });
    });
}

function setPrimaryAddress() {
    $(".js-set-primary").on("click", function (e) {
        e.preventDefault();

        window.waitMe($(this).closest(".alert"), $(this).data("loading-text"));

        const addressID = $(this).data("address-id");
        const token = $(this).data("token");

        if (addressID) {
            $.ajax({
                url: window.GLOBALS.baseLink + urls.setPrimaryAddress,
                type: "POST",
                data: {AddressID: addressID, Token: token},
                dataType: "json",
              
                success: function (response) {
                    if (response) {
                        if (response.IsSuccess) {
                            window.toastMessage(
                                response.Title,
                                response.Message,
                                response.Class
                            );
                            if (response.Redirect) {
                                setTimeout(function () {
                                    window.location.href = response.Redirect;
                                }, 1000);
                            }
                            window.closeWaitMe($(this).closest(".alert"));
                        } else {
                            window.toastMessage(
                                response.Title,
                                response.Message,
                                response.Class
                            );
                            window.closeWaitMe($(this).closest(".alert"));
                        }
                    }
                },
            });
        }
    });
}

function deleteAddress()
{
  $('.js-delete-address').on('click', function(e) {
    e.preventDefault();

    const addressID = $(this).data('address-id');
    const token = $(this).data('token');

    window.waitMe($(this).closest(".alert"), $(this).data("loading-text"));
    if(addressID)
    {
      Swal.fire({
        title: 'UYARI',
        text: 'Seçilen adres kalıcı olarak silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2BC155',
        cancelButtonColor: '#d33',
        cancelButtonText: 'VAZGEÇ',
        confirmButtonText: 'SİL'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            url: window.GLOBALS.baseLink + urls.deleteAddress,
            type: 'POST',
            data: {AddressID: addressID, Token: token},
            dataType: 'json',
            success: function(response) {
              if (response) {
                if (response.IsSuccess) {
                  window.toastMessage(
                    response.Title,
                    response.Message,
                    response.Class
                  );
                  if (response.Redirect) {
                    setTimeout(function () {
                      window.location.href = response.Redirect;
                    }, 1000)
                  }
                  window.closeWaitMe($(this).closest(".alert"));
                } else {
                  window.toastMessage(
                    response.Title,
                    response.Message,
                    response.Class
                  );
                  window.closeWaitMe($(this).closest(".alert"));
                }
              }
            }
          })
        }
      })
    }
  })
}