import urls from "../urls";

export default function () {
  $(".js-register-form").on("submit", function (e) {
    e.preventDefault();

    if (!$(this).valid()) return;

    $('[name=Phone]').unmask();
    const formData = new FormData($(this)[0]);
    const $that = $(this);
    window.waitMe($(this));

    $.ajax({
      url: window.GLOBALS.baseLink + urls.register,
      type: "POST",
      data: formData,
      dataType: "json",
      contentType: false,
      processData: false,
      success: function (response) {
        if (response) {
          if (response.IsSuccess) {
            window.toastMessage(response.Title, response.Message, response.Class);
            if (response.Redirect) {
              setTimeout(function () {
                window.location.href = response.Redirect;
              }, 1000);
            }
            window.closeWaitMe($that);
          } else {
            window.toastMessage(response.Title, response.Message, response.Class);
            window.closeWaitMe($that);
          }
        }
      },
    });
  });
}
