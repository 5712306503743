import urls from "../urls";
import { tl_format, scrollToElement } from "../common/constants";

export default function () {
    $(window).on("resize", function () {
        fixHeighter();
    });
    fixHeighter();
    domainTypeChanger();
    checkDomain();
    tabChanger();
    saveOrder();
}

function fixHeighter() {
    $(".js-fix-height-wrapper").each(function () {
        const $this = $(this);
        // Cache the highest
        var highestBox = 0;

        // Select and loop the elements you want to equalise
        $(".js-fix-height", $this).each(function () {
            // If this box is higher than the cached highest then store it
            if ($(this).height() > highestBox) {
                highestBox = $(this).height();
            }
        });

        // Set the height of all those children to whichever was highest
        $(".js-fix-height > .pricing-table", this).height(highestBox);
    });
}

function domainTypeChanger() {
    // tab secimine gore domain durumu belirlenir
    $(".js-domain-tab-changer").on("click", function (event) {
        if ($(event.target).attr("id") == "new-tab") {
            $("input[name=DomainIsHave]").val(0);
        }

        if ($(event.target).attr("id") == "have-tab") {
            $("input[name=DomainIsHave]").val(1);
        }
    });
}

function checkDomain() {
    $(".js-form-check-domain").on("click", function (e) {
        e.preventDefault();
        const searchedDomain = $("[name=SearchedDomain]").val();

        if (searchedDomain) {
            const $resultWrapper = $(".js-search-results");

            const $that = $(this).closest(".tab-pane");
            window.waitMe($that);

            $.ajax({
                url: window.GLOBALS.baseLink + urls.checkDomain,
                method: "POST",
                data: { Domain: searchedDomain },
                dataType: "json",
                success: function (response) {
                    $resultWrapper.removeClass("d-none");
                    $resultWrapper.html("");
                    let template = "";

                    if (response.IsSuccess) {
                        const result = JSON.parse(response.Result);
                        if (result) {
                            template = `
                            <div class="table-responsive my-4">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                        <th>Domain</th>
                                        <th>Domain Fiyatı</th>
                                        <th>Durumu</th>
                                        <th>İşlem</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="js-domain">
                                                ${result.domain}
                                            </td>
                                            <td class="js-domain-price">
                                                ${
                                                    !result.available
                                                        ? "-"
                                                        : tl_format(
                                                              result.format_price
                                                          )
                                                }
                                            </td>
                                            <td class="js-domain-status">
                                                ${
                                                    result.available == true
                                                        ? '<span class="badge bg-success text-white">Kullanılabilir</span>'
                                                        : '<span class="badge bg-danger text-white">Kullanılabilir Değil</span>'
                                                }
                                            </td>
                                            <td>
                                                <button ${
                                                    result.available == false
                                                        ? 'readonly disabled="disabled"'
                                                        : null
                                                } class="btn btn-primary btn-sm js-use-domain" data-domain="${
                                result.domain
                            }" data-domain-price="${result.format_price}">
                                                    Domaini Kullan
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        `;
                        }

                        if (result.code) {
                            template = `
                            <span class="badge bg-danger text-white d-block my-4 py-3">
                                Geçerli bir domain adresi giriniz.
                            </span>
                        `;
                        }
                    }

                    $resultWrapper.html(template);
                    window.closeWaitMe($that);
                },
            });
        }
    });

    $(document).on("click", ".js-use-domain", function (e) {
        e.preventDefault();
        const domain = $(this).data("domain");
        const domainPrice = $(this).data("domain-price");
        if (domain) {
            $("[name=Domain]").val(domain);
            $("[name=DomainPrice]").val(domainPrice);

            $(this)
                .addClass("btn-success")
                .html('<i class="fa fa-check js-domain-btn-show"></i> Seçildi');
            window.toastMessage(
                "Bilgilendirme",
                `${domain} domaini seçildi`,
                "success"
            );
        }
    });
}

function tabChanger() {
    $(".js-select-type").on("click", function (e) {
        e.preventDefault();

        if ($(this).hasClass("active")) {
            return;
        }

        if (!checkRequiredInput()) return;

        const tabType = $(this).data("tab-type");

        $(".js-select-type")
            .closest(".js-payment-item")
            .removeClass("active selected");
        $(this).closest(".js-payment-item").addClass("active selected");
        $(`.js-types-tab-content[data-tab-type]`).removeClass(
            "active selected"
        );
        $(`.js-types-tab-content[data-tab-type=${tabType}]`).addClass(
            "active selected"
        );
        calculatePrice(tabType);
        fillContracts();
    });

    $(".js-continue-payment").on("click", function (e) {
        e.preventDefault();
        if ($(".js-types-tab-content.active.selected input").is(":checked")) {
            setTimeout(() => {
                $(".js-register-form").submit();
            }, 250);
        } else {
            Swal.fire("Uyarı", "Lütfen sözleşmeleri onaylayınız.", "warning");
        }
    });
}

function checkRequiredInput() {
    let isNeedDomain = $("input[name=IsNeedDomain]").val(),
        domain = $("input[name=Domain]").val(),
        addressId = $("input[name=SelectedAddressID]:checked").val(),
        granted = false;

    if (!addressId || addressId == undefined) {
        window.toastMessage("Uyarı", `Lütfen Adres Seçimi Yapınız`, "warning");
        scrollToElement(".needs-validation", 250);
        granted = false;
    } else {
        granted = true;
    }

    if (isNeedDomain != 0) {
        if (domain.length == 0 || domain == undefined) {
            window.toastMessage(
                "Uyarı",
                `Lütfen Domain Seçimi Yapınız`,
                "warning"
            );
            scrollToElement(".js-domain-tab-changer", 250);
            granted = false;
        } else {
            granted = true;
        }
    } else {
        granted = true;
    }
    return granted;
}

function calculatePrice(type) {
    if (type) {
        const isNeedDomain = $("input[name=IsNeedDomain]").val();
        const domainIsHave = parseInt(
            $("form").find("[name=DomainIsHave]").val()
                ? $("form").find("[name=DomainIsHave]").val()
                : 0
        );
        const domain = $("form").find("[name=Domain]").val();
        const searchedDomain = $("form").find("[name=SearchedDomain]").val();
        const searchedDomainPrice = $("form").find("[name=DomainPrice]").val();
        const formData = {
            PackageID: $("form").find("[name=PackageID]").val(),
            _token: $("form").find("[name=_token]").val(),
            ReservOrderID: $("form").find("[name=ReservOrderID]").val(),
            IsNeedDomain: isNeedDomain,
            DomainIsHave: domainIsHave,
            Domain: domain,
            DomainPrice: domainIsHave ? 0 : searchedDomainPrice,
        };

        $.ajax({
            url: window.GLOBALS.baseLink + urls.calculatePrice,
            type: "POST",
            data: formData,
            dataType: "json",
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        $(`div[data-tab-type=${type}]`)
                            .find(".js-payment-amount")
                            .text(response.TotalPrice + " TL");
                        $(`input[name=TotalAmount]`).val(response.TotalPrice);
                        const $selector = $(`div[data-tab-type=${type}]`).find(
                            ".js-summary-table"
                        );
                        fillSummaryTable($selector, response.OrderItems);
                    }
                }
            },
        });
    }
}

function saveOrder() {
    $(".js-register-form").on("submit", function (e) {
        e.preventDefault();

        if (!$(this).valid()) return;

        if (!checkRequiredInput()) return;

        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this));

        const activePaymentType = $(".js-select-type.active.selected").data(
            "tab-type"
        );
        const PaymentTypeID = $(".js-types-tab-content.active.selected")
            .find("[name=PaymentTypeID]")
            .val();
        const PaymentTypeText = $(".js-types-tab-content.active.selected")
            .find("[name=PaymentTypeText]")
            .val();
        const isNeedDomain = $("input[name=IsNeedDomain]").val();
        const domainIsHave = parseInt(
            $("form").find("[name=DomainIsHave]").length
                ? $("form").find("[name=DomainIsHave]").val()
                : 0);
        const domain = isNeedDomain && (domainIsHave ? $("form").find("[name=Domain]").val() : '');

        // Remove form older data
        formData.delete('PaymentTypeID');
        formData.delete('PaymentTypeText');
        formData.delete('g-recaptcha-response');
        formData.delete('IsNeedDomain');


        formData.append("PaymentTypeID", PaymentTypeID);
        formData.append("PaymentTypeText", PaymentTypeText);
        formData.append("IsNeedDomain", isNeedDomain);
        formData.append("DomainIsHave", domainIsHave);
        formData.append("Domain", domain);

        const requestURL = PaymentTypeID == 1 ? urls.prepare3dPayment : urls.saveOrder;

        $.ajax({
            url: window.GLOBALS.baseLink + requestURL,
            type: "POST",
            data: formData,
            dataType: "json",
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        if (PaymentTypeID == 1 && response.RedirectLink) {
                            setTimeout(function () {
                                window.location.href = response.RedirectLink;
                            }, 1000);
                            window.closeWaitMe($that);
                        }
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000);
                        }
                        window.closeWaitMe($that);
                    } else {
                        window.toastMessage(
                            response.Title,
                            response.Message,
                            response.Class
                        );
                        window.closeWaitMe($that);
                    }
                }
            },
        });
    });
}

function fillSummaryTable(selector, items) {
    if (items) {
        let template = "";
        $.each(items, function (i, e) {
            template += `<tr>
              <td>${e.Name.replace("<br>", "")}</td>
              <td>${e.Price} TL</td>
          </tr>`;
        });
        if (template) {
            selector.find("tbody").html(template);
        }
    }
}

function fillContracts() {
    const isNeedDomain = $("input[name=IsNeedDomain]").val();
    const domainIsHave = parseInt(
        $("form").find("[name=DomainIsHave]").length
            ? $("form").find("[name=DomainIsHave]").val()
            : 0
    );
    const domain = $("form").find("[name=Domain]").val();
    const searchedDomain = $("form").find("[name=SearchedDomain]").val();
    const searchedDomainPrice = $("form").find("[name=DomainPrice]").val();
    let domainPrice = 0;

    if (isNeedDomain != 0) {
        domainPrice = domainIsHave ? 0 : searchedDomainPrice;
    } else {
        domainPrice = 0;
    }
    const formData = {
        SelectedAddressID: $("form")
            .find("[name=SelectedAddressID]:checked")
            .val(),
        PackageID: $("form").find("[name=PackageID]").val(),
        _token: $("form").find("[name=_token]").val(),
        ReservOrderID: $("form").find("[name=ReservOrderID]").val(),
        IsNeedDomain: isNeedDomain,
        DomainIsHave: domainIsHave,
        Domain: domain ? domain : null,
        DomainPrice: domainPrice,
        NameSurname: $("input[name=NameSurname]").val(),
        PaymentType:
            $(
                ".js-types-tab-content.active.selected input[name=PaymentTypeID]"
            ).val() == 1
                ? "Kredi Kartı"
                : "Havale",
    };

    $.ajax({
        url: window.GLOBALS.baseLink + urls.fillContracts,
        method: "POST",
        data: formData,
        dataType: "json",
        success: function (response) {
            if (response.IsSuccess) {
                if ($(document).find(".js-sales-contract-text").length) {
                    $(document)
                        .find(".js-sales-contract-text")
                        .html(response.Content);
                }
            }
        },
    });
}
