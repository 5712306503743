import $ from "jquery";
import Swal from "sweetalert2";
import urls from "../urls";

export default function () {
    $(".js-cancel-service").on("click", function (e) {
        e.preventDefault();

        const orderID = $(this).data("order-id");
        const customerID = $(this).data("customer-id");

        let selectedValue = "";
        Swal.fire({
            title: "İptal etme nedeninizi seçiniz",
            input: "select",
            inputOptions: {
                "-1": "Seçiniz",
                "Yanlış ürün aldım": "Yanlış Ürün Aldım",
                "Satın almaktan vazgeçtim": "Satın almaktan vazgeçtim",
                "Daha ucuzunu buldum": "Daha ucuzunu buldum",
                "Ortağımla ters düştüm": "Ortağımla ters düştüm",
                Diğer: "Diğer",
            },
            showCancelButton: true,
            cancelButtonText: "Kullanmaya Devam Et",
            cancelButtonColor: "#198754",
            confirmButtonText: "Talep Oluştur",
            confirmButtonColor: "#dc3545",
            showLoaderOnConfirm: true,
            preConfirm: (selected) => {
                if (selected == "-1") {
                    Swal.fire({
                        title: `Uyarı`,
                        text: "Lütfen seçim yapınız",
                    });
                }

                const request = {
                    OrderID: orderID,
                    CustomerID: customerID,
                    Reason: selected,
                };

                if (request) {
                    $.ajax({
                        url: window.GLOBALS.baseLink + urls.cancelRequest,
                        type: "POST",
                        data: request,
                        dataType: "json",
                        success: function (response) {
                            if (response) {
                                if (response.IsSuccess) {
                                    window.toastMessage(
                                        response.Title,
                                        response.Message,
                                        response.Class
                                    );
                                    if (response.Redirect) {
                                        setTimeout(function () {
                                            window.location.href =
                                                response.Redirect;
                                        }, 1000);
                                    }
                                    window.closeWaitMe($that);
                                } else {
                                    window.toastMessage(
                                        response.Title,
                                        response.Message,
                                        response.Class
                                    );
                                    window.closeWaitMe($that);
                                }
                            }
                        },
                    });
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            // if (result.isConfirmed) {
            //     Swal.fire({
            //         title: `${result.value.login}'s avatar`,
            //         imageUrl: result.value.avatar_url
            //     })
            // }
        });
    });
}
