import $ from 'jquery';
import Swal from 'sweetalert2';
import {
    scrollToElement,
    getCityByCountry,
    emailRegex,
    domainRegex,
    tl_format,
} from '../common/constants';
import urls from '../urls';
import login from './login';
import register from './register';


export default function () {
    // login formu
    login();

    // register formu
    register();

    $('.js-select-package').on('click', function(e) {
        e.preventDefault();
        $('.js-select-package').text('SEÇ');

        const val = $(this).data('package-id');
        if(val)
        {
            $('[name=PackageID]').val(val);
            $(this).text('SEÇİLDİ');

            if(val == 2 || val == 3)
            {
                $(document).find('.js-register-domain').show().closest('.container').show();
                $(document).find('.js-register-customer').closest('.container').find('span.title-pagination').text('3')
            }else {
                $('.js-register-domain').addClass('js-hided').hide().closest('.container').hide();
                $('.js-register-customer').closest('.container').find('span.title-pagination').text('2')
            }
            
        }
    })

    // Secili olani gosterir
    $('.js-register-select-package li.js-package-item').on('click', function (e) {
 
        $('.js-register-select-package li.js-package-item').removeClass('selected');
        $(this).addClass('selected');
    })

    // tab secimine gore domain durumu belirlenir
    $('button[data-bs-toggle="tab"]').on('shown.bs.tab', function (event) {
        if ($(event.target).attr('id') == 'new-tab') {
            $('input[name=DomainIsHave]').val(0)
        }

        if ($(event.target).attr('id') == 'have-tab') {
            $('input[name=DomainIsHave]').val(1)
        }
    })

    // Eger Sirkete fatura kesilecekse gosterilecek alanlar
    $('input[name=IsCompany]').on('change', function (e) {
        if ($(this).is(':checked')) {
            $('.js-company-div').removeClass('d-none');
        } else {
            $('.js-company-div').addClass('d-none');
        }
    });

    setTimeout(() => {
        $('#myTab li.nav-item:first button').click();
        autoSelectPackage();

    }, 250)

    // Ulke secimine gore sehirleri getiren fonksiyon
    getCityByCountry('.js-select-country', '.js-city-wrapper', 'CityID');
 
    // register();
    buyPackage();
    forgotPassword();
    saveForgotPassword();
    checkDomain();
    calculatePrice();
    paymentTabController();
    checkEmailIsAvailable();
    // checkDomainIsValid();

    $('#myTab [data-bs-toggle=tab]').on('click', function(e){
        // checkDomainIsValid();
    })

    $('.js-open-contract-popup').on('click', function(e){
        $('#sales_contract_modal').modal('show');
    })
    
    $('.js-close-contract-popup').on('click', function(e){
        $('#sales_contract_modal').modal('hide');
    })
    
}



function forgotPassword() {
    $('.js-fp-form').on('submit', function (e) {
        e.preventDefault();

        if (!$(this).valid())
            return;

        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this));

        $.ajax({
            url: window.GLOBALS.baseLink + 'sifre-sifirla',
            type: 'POST',
            data: formData,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        Swal.fire(response.Title, response.Message, response.Class, );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000)
                        }
                        window.closeWaitMe($that);
                    } else {
                        Swal.fire(response.Title, response.Message, response.Class);
                        window.closeWaitMe($that);
                    }
                }
            }
        })


    })
}

function saveForgotPassword() {
    $('.js-fp-update-form').on('submit', function (e) {
        e.preventDefault();

        if (!$(this).valid())
            return;

        const formData = new FormData($(this)[0]);
        const $that = $(this);
        window.waitMe($(this));

        $.ajax({
            url: window.GLOBALS.baseLink + 'sifre-kaydet', 
            type: 'POST',
            data: formData,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        Swal.fire(response.Title, response.Message, response.Class, );
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.replace(response.Redirect);
                            }, 1000)
                        }
                        window.closeWaitMe($that);
                    } else {
                        Swal.fire(response.Title, response.Message, response.Class);
                        window.closeWaitMe($that);
                    }
                }
            }
        })
    })
}

function buyPackage() {
    setTimeout(() => {
        $('.js-select-country').change();
    }, 1000);
    
    $('.js-buy-package-form').on('submit', function (e) {
        e.preventDefault();

        if (!$(this).valid())
            return;
        
        if(!checkUnSelectInputs())
            return;
            
        $(this).find('.js-register-button').css('pointer-events', 'none');

        const formData = new FormData($(this)[0]);

        const languageData = [];
        $('input[name=LanguageID]:checked').each(function () {
            languageData.push($(this).val());
        });
        formData.append('LanguageID', languageData);

        const activePaymentType = $('.js-select-type.active.selected').data('tab-type');
        const PaymentTypeID = $('.js-types-tab-content.active.selected').find('[name=PaymentTypeID]').val();
        const PaymentTypeText = $('.js-types-tab-content.active.selected').find('[name=PaymentTypeText]').val();

        formData.append("PaymentTypeID", PaymentTypeID)
        formData.append("PaymentTypeText", PaymentTypeText)

        const requestURL = 'paket-satin-al';
        
        $.ajax({
            url: requestURL,
            type: 'POST',
            data: formData,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        Swal.fire(response.Title, response.Message, response.Class);
                        if(PaymentTypeID == 1){
                            $('#creditform').submit();
                        }
                        if (response.Redirect) {
                            setTimeout(function () {
                                window.location.href = response.Redirect;
                            }, 1000)
                        }
                        $(this).find('.js-register-button').css('pointer-events', 'auto');
                    } else {
                        Swal.fire(response.Title, response.Message, response.Class);
                        $(this).find('.js-register-button').css('pointer-events', 'auto');
                    }
                }else{
                    $(this).find('.js-register-button').css('pointer-events', 'auto');
                }
            }
        })
    })
}

function checkDomain() {
    $('.js-form-check-domain').on('click', function(e) {
        e.preventDefault();
        const searchedDomain = $('[name=SearchedDomain]').val();

        if(searchedDomain)
        {
            const $resultWrapper = $('.js-search-results');

            const $that = $(this).closest('.tab-pane');
            window.waitMe($that);
            
            $.ajax({
                url: window.GLOBALS.baseLink + 'check-domain',
                method: 'POST',
                data: {Domain: searchedDomain},
                dataType: 'json',
                success: function(response) {
                    $resultWrapper.removeClass('d-none');
                    $resultWrapper.html('');
                    let template = '';
                    

                    if(response.IsSuccess)
                    {
                        const result = JSON.parse(response.Result);
                        if(result)
                        {
                            
                            template = `
                                <div class="table-responsive my-4">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                            <th>Domain</th>
                                            <th>Domain Fiyatı</th>
                                            <th>Durumu</th>
                                            <th>İşlem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="js-domain">
                                                    ${result.domain}
                                                </td>
                                                <td class="js-domain-price">
                                                    ${!result.available ? '-' : tl_format(result.format_price)}
                                                </td>
                                                <td class="js-domain-status">
                                                    ${result.available == true ?    '<span class="badge bg-success text-white">Kullanılabilir</span>' : '<span class="badge bg-danger text-white">Kullanılabilir Değil</span>'
                                                    }
                                                </td>
                                                <td>
                                                    <button ${result.available == false ? 'readonly disabled="disabled"' : null} class="btn btn-success btn-sm js-use-domain" data-domain="${result.domain}" data-domain-price="${result.format_price}">
                                                        Domaini Kullan
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            `;
                        }

                        if(result.code)
                        {
                            template = `
                                <span class="badge bg-danger text-white d-block my-4 py-3">
                                    Geçerli bir domain adresi giriniz.
                                </span>
                            `;
                        }
                    }

                    $resultWrapper.html(template);
                    window.closeWaitMe($that)
                }
            })
        }
    })

    $(document).on('click', '.js-use-domain', function(e) {
        e.preventDefault();
        const domain = $(this).data('domain');
        const domainPrice = $(this).data('domain-price');
        if(domain)
        {
            $('[name=Domain]').val(domain);
            $('[name=DomainPrice]').val(domainPrice);
            Swal.fire('', domain + ' domaini seçildi', 'success')
        }
    })
}

function calculatePrice(type) {
    if(type)
    {
        const domainIsHave = parseInt($('form').find('[name=DomainIsHave]').val());
        const domain = $('form').find('[name=Domain]').val();
        const searchedDomain = $('form').find('[name=SearchedDomain]').val();
        const searchedDomainPrice = $('form').find('[name=DomainPrice]').val();
        const formData = {
            "PackageID": $('form').find('[name=PackageID]').val(),
            "_token": $('form').find('[name=_token]').val(),
            "ReservOrderID": $('form').find('[name=ReservOrderID]').val(),
            "DomainIsHave": domainIsHave,
            "Domain": domain,
            "DomainPrice": domainIsHave ? 0 : searchedDomainPrice
        }
        
        $.ajax({
            url: window.GLOBALS.baseLink + 'calculate',
            type: 'POST',
            data: formData,
            dataType: 'json',
            success: function (response) {
                if (response) {
                    if (response.IsSuccess) {
                        $(`div[data-tab-type=${type}]`).find('.js-payment-amount').text(response.TotalPrice + ' TL');
                        $(`input[name=TotalAmount]`).val(response.TotalPrice);
                        const $selector = $(`div[data-tab-type=${type}]`).find('.js-summary-table')
                        fillSummaryTable($selector, response.OrderItems);
                    }
                }
            }
        })
    }
}

function fillSummaryTable(selector, items)
{
    if(items)
    {
        let template = '';
        $.each(items, function(i, e){
            template += `<tr>
                <td>${e.Name.replace('<br>', '')}</td>
                <td>${e.Price} TL</td>
            </tr>`
        })
        if(template)
        {
            selector.find('tbody').html(template);
        }
    }
}

function paymentTabController() {
    $('.js-select-type').on('click', function(e) {
        e.preventDefault();

        if($(this).hasClass('active'))
        {
            return;
        }

        if(!checkUnSelectInputs())
            return;

        const tabType = $(this).data('tab-type');

        $('.js-select-type').removeClass('active selected');
        $(this).addClass('active selected');
        $(`.js-types-tab-content[data-tab-type]`).removeClass('active selected');
        $(`.js-types-tab-content[data-tab-type=${tabType}]`).addClass('active selected');
        calculatePrice(tabType);
        fillContracts();
    })

    $('.js-continue-payment').on('click', function(e){
        e.preventDefault();
        if($('.js-types-tab-content.active.selected input').is(':checked')){
            setTimeout(() => {
                $('.js-register-form').submit();
            }, 250); 
        }else{
            Swal.fire('Uyarı', 'Lütfen sözleşmeleri onaylayınız.', 'warning');
        }
    })
}

function checkUnSelectInputs()
{
    let   packageId = $('input[name=PackageID]').val(),
            domain = $('input[name=Domain]').val(),
            invoiceInfoInput = $('.js-register-customer input:visible:not([type=checkbox])'),
            invoiceInfoSelect = $('.js-register-customer select:visible'),
            invoiceInfoTextarea = $('.js-register-customer textarea:visible');
    let granted = false, invoiceInputGranted = false;
    
    if(packageId == '' || packageId == undefined )
    {
        Swal.fire('Uyarı', 'Lütfen Paket Seçimi Yapınız', 'warning');
        scrollToElement('.js-register-select-package', 250);
    }

    if(domain == '' || domain == undefined ){
        if(packageId != 1 && packageId != 4){
            Swal.fire('Uyarı', 'Lütfen Domain Seçimi Yapınız', 'warning');
            scrollToElement('.js-register-domain', 250);
        }else {
            domain = true;
        }
    }

    if (!invoiceInfoInput.valid()) 
    {
        Swal.fire('Uyarı', 'Kişisel ve Fatura Bilgileriniz Giriniz', 'warning');
        scrollToElement('.js-register-customer', 250);
    }else if (!invoiceInfoSelect.valid()) 
    {
        Swal.fire('Uyarı', 'Kişisel ve Fatura Bilgileriniz Giriniz', 'warning');
        scrollToElement('.js-register-customer', 250);
    }else if (!invoiceInfoTextarea.valid()) 
    {
        Swal.fire('Uyarı', 'Kişisel ve Fatura Bilgileriniz Giriniz', 'warning');
        scrollToElement('.js-register-customer', 250);
    }else {
        invoiceInputGranted = true;
    }

    // if(packageId == '' || packageId == undefined )
    // {
    //     Swal.fire('Uyarı', 'Lütfen Paket Seçimi Yapınız', 'warning');
    //     scrollToElement('.js-register-select-package', 250);
    // }else if(domain == '' || domain == undefined){
    //     if(packageId == 1 || packageId == 4)
    //     {
    //     }else {
    //         Swal.fire('Uyarı', 'Lütfen Domain Seçimi Yapınız', 'warning');
    //         scrollToElement('.js-register-domain', 250);
    //     }
    // }else {
    //     if (!invoiceInfoInput.valid()) 
    //     {
    //         Swal.fire('Uyarı', 'Kişisel ve Fatura Bilgileriniz Giriniz', 'warning');
    //         scrollToElement('.js-register-customer', 250);
    //     }else if (!invoiceInfoSelect.valid()) 
    //     {
    //         Swal.fire('Uyarı', 'Kişisel ve Fatura Bilgileriniz Giriniz', 'warning');
    //         scrollToElement('.js-register-customer', 250);
    //     }else if (!invoiceInfoTextarea.valid()) 
    //     {
    //         Swal.fire('Uyarı', 'Kişisel ve Fatura Bilgileriniz Giriniz', 'warning');
    //         scrollToElement('.js-register-customer', 250);
    //     }else {
    //         invoiceInputGranted = true;
    //     }
    // }
    if(packageId && domain && invoiceInputGranted)
    {
        granted = true;
    }else {
        granted = false
    }

    return granted;
}

function checkEmailIsAvailable() 
{
    const emailInput = $('.js-register-customer [name=Email]');
    
    $(emailInput).on('blur', function(e){
        e.preventDefault();
        const emailValue = $('.js-register-customer [name=Email]').val();
        
        if(emailRegex.test(emailValue))
        {
            $(this).removeClass('border-danger');
            $(this).addClass('border-success');
            if(emailValue) {
                $.ajax({
                    url: window.GLOBALS.baseLink + 'check-email',
                    method: 'POST',
                    data: {Email: emailValue},
                    dataType: 'json',
                    success: function(response) {
                        if(!response.IsSuccess)
                        {
                            Swal.fire(response.Title, response.Message, response.Class);                
                        }
                    }
                })
            }
        }else {
            $(this).removeClass('border-success');
            $(this).addClass('border-danger');
        }
        
    })
}

function checkDomainIsValid() 
{
    $('#myTabContent .tab-pane.active').find('[type=search]:visible').on('blur', function(e){
        e.preventDefault();
        const val = $('#myTabContent .tab-pane.active').find('[type=search]:visible').val();

        if(domainRegex.test(val)){
            $(this).removeClass('border-danger');
            $(this).addClass('border-success');
        }else{
            $(this).addClass('border-danger');
            $(this).removeClass('border-success');
            Swal.fire('Uyarı', 'Lütfen geçerli bir domain giriniz', 'warning');                
        }
    })
}

function autoSelectPackage()
{
    const params = window.location.search;
    const splitParams =  params.split('?package=');
    const id = splitParams.pop();
    
    if(id)
    {
        $.each($('.js-select-package'), function(i,e){
            if($(`.js-select-package[data-package-id=${id}]`).length > 0)    
            {
                $('[name=PackageID]').val(id);
                $(`.js-select-package[data-package-id=${id}]`).text('SEÇİLDİ').click();
            }
        });
    }
}

function fillContracts()
{   
    const domainIsHave = parseInt($('form').find('[name=DomainIsHave]').val());
    const domain = $('form').find('[name=Domain]').val();
    const searchedDomain = $('form').find('[name=SearchedDomain]').val();
    const searchedDomainPrice = $('form').find('[name=DomainPrice]').val();
    const formData = {
        "PackageID": $('form').find('[name=PackageID]').val(),
        "_token": $('form').find('[name=_token]').val(),
        "ReservOrderID": $('form').find('[name=ReservOrderID]').val(),
        "DomainIsHave": domainIsHave,
        "Domain": domain,
        "DomainPrice": domainIsHave ? 0 : searchedDomainPrice,
        "NameSurname": $('input[name=NameSurname]').val(),
        "Email": $('input[name=Email]').val(),
        "Adress": $('textarea[name=Address]').val() + ' ' + $('.js-city-wrapper option:selected').text() + ' ' + $('.js-select-country option:selected').text(),
        "Phone": $('input[name=Phone]').cleanVal(),
        "Identity": $('input[name=IdentityNumber]').val(),
        "PaymentType": $('.js-types-tab-content.active.selected input[name=PaymentTypeID]').val() == 1 ? 'Kredi Kartı' : 'Havale'
    }

    $.ajax({
        url: window.GLOBALS.baseLink + 'contracts',
        method: 'POST',
        data: formData,
        dataType: 'json',
        success: function(response) {
            if(response.IsSuccess)
            {
                if($('.js-sales-contract-text').length)
                {
                    $('.js-sales-contract-text').html(response.Content);
                }
            }
        }
    })
}