import homepage from "js/homepage/";
import auth from "js/auth";
import profile from "js/profile";
import ticket from "js/ticket";
import contact from "js/contact";
import service from "js/service";
import packages from "js/packages";

const globals = window.GLOBALS;

const route = {
    homepage: homepage,
    auth: auth,
    profile: profile,
    ticket: ticket,
    contact: contact,
    service: service,
    packages: packages,
};

if (typeof globals.page != "undefined") {
    globals.page.constructor === Array
        ? globals.page.map((item) => {
              route && route[item] && route[item]();
          })
        : route && route[globals.page] && route[globals.page]();
}
