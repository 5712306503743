import '../less/index.less'

import $ from 'jquery'
window.jQuery = $
window.$ = $

import 'js/appRouter'
import '../../node_modules/jquery-validation/dist/localization/messages_tr';
import '../../node_modules/jquery-validation/dist/jquery.validate';
import '../../node_modules/jquery-mask-plugin/dist/jquery.mask';
import './common/weepay.min.js';
import common from 'js/common/index';


(function () {
  common();

  $('[data-phone-mask]').mask("0(500) 000 00 00", {
    placeholder: "0(501) 234 56 78",
  });
  
  $('[data-date-mask]').mask("00.00.0000", {
    placeholder: "gg.aa.YYYY"
  });
  
  $('[data-cc-mask]').mask("0000 0000 0000 0000", {
    placeholder: "1234 5678 5678 1234"
  });

})();
